import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, Icon } from '@mui/material';
import { AxiosError } from 'axios';

import Loading from '@/components/utility/Loading';
import NumberStepper from '@/components/utility/microcomponents/NumberStepper';
import DialogModal from '@/components/utility/modals/DialogModal';
import { WebsiteBuilderBackgrounds } from '@/constants/WebsiteBuilderBackgrounds';
import { PromoteFlowStepFormatter } from '@/formatters/PromoteFlowStepFormatter';
import useArtist from '@/hooks/artist/useArtist';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useUserTracking from '@/hooks/useUserTracking';
import useScrollToTop from '@/hooks/utility/useScrollToTop';
import useWebsite from '@/hooks/website-builder/useWebsite';
import { MetaErrorModel, MetaErrorValidationErrorModel } from '@/models/Meta';
import { WebsiteBuilderModel } from '@/models/WebsiteBuilder';
import WebsiteBuilderAPI from '@/network/WebsiteBuilderAPI';
import { handleApiError } from '@/utility/api';

import BackgroundPickerPage from './website-generator-steps/BackgroundPickerPage';
import ChooseURLPath from './website-generator-steps/ChooseURLPath';
import CommunityPage from './website-generator-steps/CommunityPage';
import PlatformLinks from './website-generator-steps/PlatformLinks';
import SpotlightReleasePage from './website-generator-steps/SpotlightRelease';
import TrackingPixelsPage from './website-generator-steps/TrackingPixels';
import ViewWebsitePage from './website-generator-steps/ViewWebsitePage';
import WebsiteBuilderLandingPage from './website-generator-steps/WebsiteBuilderLandingPage';
import WebsitePreviewPage from './website-generator-steps/WebsitePreviewPage';
import WebsiteSummaryPage from './website-generator-steps/WebsiteSummaryPage';
import WebsiteWalkthrough from './website-generator-steps/WebsiteWalkthrough';
import YoutubeVideoPage from './website-generator-steps/YoutubeVideoPage';

const WebsiteGeneratorModal = ({ closeModalOutput }: { closeModalOutput: () => void }) => {
  const { t } = useTranslation();
  const { scrollToTopInPromoteFlows } = useScrollToTop();

  const { dispatchSnackbar } = useSnackbarContext();

  const { accountId } = useAccountContext();
  const { artist } = useArtist();
  const [canStep, setCanStep] = useState(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { website, websiteIsLoading, refetchWebsite, websiteError } = useWebsite();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [permissionDialogOpen, setPermissionDialogOpen] = useState<boolean>(false);

  const [isCreatingWebsite, setIsCreatingWebsite] = useState<boolean>(false);

  const [step, setStep] = useState<number>(0);
  const userTracking = useUserTracking();
  const [errors, setErrors] = useState<MetaErrorValidationErrorModel[]>();

  const fanHub = useMemo(() => {
    return {
      productType: 'Fan Hub',
      priceId: '',
      platform: 'web',
      orderDetails: {
        totalValue: null,
        totalQuantity: 1,
        currency: null,
        products: [
          {
            quantity: 1,
            productID: 'fan_hub_001',
            price: null,
            name: 'Fan Hub',
            currency: null,
          },
        ],
      },
    };
  }, []);

  const sendInitiateFlow = useCallback(() => {
    if (userTracking && fanHub) return userTracking.userInitiatedProFeature(fanHub, 'Fan Hub');
  }, [fanHub, userTracking]);

  const sendCancelFlow = useCallback(() => {
    if (userTracking && fanHub) return userTracking.userCancelledProFeature(fanHub, 'Fan Hub');
  }, [fanHub, userTracking]);

  const sendPublishFlow = useCallback(() => {
    if (userTracking && fanHub) return userTracking.userPublishProFeature(fanHub, 'Fan Hub');
  }, [fanHub, userTracking]);

  useEffect(() => {
    sendInitiateFlow();
  }, [sendInitiateFlow]);

  const trackProductViewed = useCallback(() => {
    if (!userTracking) return;
    if (!websiteIsLoading && (isEditMode || (!website && !isEditMode))) {
      return userTracking?.productViewed?.({
        product: 'Fan Hub',
        productScreenName: PromoteFlowStepFormatter('fan-hub', step),
      });
    }
  }, [userTracking, step, isEditMode, website, websiteIsLoading]);

  useEffect(() => {
    trackProductViewed();
  }, [trackProductViewed]);

  const NUMBER_OF_STEPS = 11;

  const defaultValues = useMemo(
    () => ({
      accountId: undefined,
      artistId: undefined,
      artistName: undefined,
      profileImageUrl: undefined,
      route: undefined,
      bio: '',
      primaryLinks: undefined,
      spotlightRelease: undefined,
      video: undefined,
      platformLinks: undefined,
      community: {
        collectEmails: true,
        collectPhoneNumbers: true,
      },
      mailingList: undefined,
      trackingPixels: undefined,
      theme: {
        background: WebsiteBuilderBackgrounds.images[0].name,
      },
    }),
    []
  );

  const formMethods = useForm<WebsiteBuilderModel>({ defaultValues });

  useEffect(() => {
    if (accountId) {
      formMethods.setValue('accountId', accountId);
    }
  }, [accountId, formMethods]);

  useEffect(() => {
    if (artist) {
      formMethods.setValue('artistId', artist.id);
      formMethods.setValue('artistName', artist.details.name);
      formMethods.setValue('profileImageUrl', artist.details.profileImageUrl);
      formMethods.setValue('route', artist.details.name.toLowerCase().replaceAll(' ', '-').replaceAll('.', ''));
    }
  }, [artist, formMethods]);

  const createWebsite = useCallback(async () => {
    setIsCreatingWebsite(true);
    try {
      await WebsiteBuilderAPI.createWebsite({ data: formMethods.getValues() });
      await refetchWebsite();
      setStep(NUMBER_OF_STEPS);
    } catch (error: unknown) {
      handleApiError({ error, dispatchSnackbar });
      if (error) {
        const errs = error as AxiosError<MetaErrorModel>;
        setErrors(errs.response?.data.errors);
      }
    } finally {
      sendPublishFlow();
      setIsCreatingWebsite(false);
      setPermissionDialogOpen(false);
    }
  }, [dispatchSnackbar, formMethods, refetchWebsite, sendPublishFlow]);

  const updateWebsite = useCallback(async () => {
    setIsCreatingWebsite(true);
    try {
      const formattedData = {
        ...formMethods.getValues(),
        theme: {
          background:
            typeof formMethods.getValues('theme.background') === 'string'
              ? formMethods.getValues('theme.background')
              : formMethods.getValues('theme.background.name'),
        },
      };

      await WebsiteBuilderAPI.updateWebsite({ data: formattedData });
      await refetchWebsite();
      setStep(NUMBER_OF_STEPS);
    } catch (error: unknown) {
      handleApiError({ error, dispatchSnackbar });
      if (error) {
        const errs = error as AxiosError<MetaErrorModel>;
        setErrors(errs.response?.data.errors);
      }
    } finally {
      sendPublishFlow();
      setIsCreatingWebsite(false);
      setPermissionDialogOpen(false);
    }
  }, [dispatchSnackbar, formMethods, refetchWebsite, sendPublishFlow]);

  useEffect(() => {
    if (!website || websiteError) return;

    Object.keys(defaultValues).forEach((key) => {
      formMethods.setValue(key as keyof WebsiteBuilderModel, website[key as keyof WebsiteBuilderModel] ?? undefined);
    });
    setIsEditMode(true);
    setStep(1);
  }, [defaultValues, formMethods, website, websiteError]);

  const handleDialogOutput = (output: boolean) => {
    if (!output) return setDialogOpen(false);
    sendCancelFlow();
    userTracking?.productExited?.({
      product: 'Fan Hub',
      productScreenName: PromoteFlowStepFormatter('fan-hub', step),
    });
    return closeModalOutput();
  };

  const handlePermissionDialogOutput = (output: boolean) => {
    output ? (isEditMode ? updateWebsite() : createWebsite()) : setPermissionDialogOpen(false);
  };

  const handleCanStep = useCallback(
    (value: boolean) => {
      if (value !== canStep) {
        setCanStep(value);
      }
    },
    [canStep]
  );

  return (
    <div
      className={`promote-modal-container text-center ${step === 0 && !websiteIsLoading ? 'walkthrough-screen' : ''}`}
    >
      {step !== 0 && <NumberStepper steps={NUMBER_OF_STEPS} stepNumber={step} />}
      {step === 0 && <h2>{t('HOMEPAGE.FAN-HUB')}</h2>}

      <DialogModal
        open={dialogOpen}
        title={'DIALOGS.QUIT-WEBSITE-BUILDER'}
        content={'DIALOGS.ARE-YOU-SURE-YOU-WANT-TO-QUIT'}
        output={(output) => handleDialogOutput(output)}
      />
      <DialogModal
        open={permissionDialogOpen}
        type={'permissions'}
        title={'DIALOGS.ONE-MORE-STEP'}
        content={'DIALOGS.WEBSITE-PERMISSIONS'}
        isWebsiteConfirmation={true}
        output={(output) => handlePermissionDialogOutput(output)}
      />
      <Button className="icon-btn close-button" onClick={() => setDialogOpen(true)}>
        <Icon>close</Icon>
      </Button>
      {websiteIsLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      <div className={step !== 0 ? 'mt48 mt20-lg-down' : 'mt20'}></div>
      {!websiteIsLoading && (
        <div className="w90p-lg-down w60p ml-auto mr-auto mb300">
          <FormProvider {...formMethods}>
            {step === 0 && <WebsiteWalkthrough />}
            {step === 1 && <WebsiteBuilderLandingPage isEditMode={isEditMode} canStep={handleCanStep} />}
            {step === 2 && <CommunityPage />}
            {step === 3 && <SpotlightReleasePage />}
            {step === 4 && <YoutubeVideoPage />}
            {step === 5 && <PlatformLinks />}
            {step === 6 && <TrackingPixelsPage />}
            {step === 7 && <ChooseURLPath isEditMode={isEditMode} />}
            {step === 8 && <BackgroundPickerPage />}
            {step === 9 && <WebsitePreviewPage />}
            {step === 10 && <WebsiteSummaryPage errors={errors} goToStep={(step: number) => setStep(step)} />}
            {step === 11 && <ViewWebsitePage />}
          </FormProvider>
        </div>
      )}

      <div className="promote-footer">
        <div className={`card-inner w90p p10 pl20 m-auto d-flex`}>
          <div className="ml-auto">
            {step > 1 && (
              <Button
                className="border-btn"
                disabled={step === NUMBER_OF_STEPS}
                onClick={() => {
                  setStep(step > 1 ? step - 1 : step);
                  scrollToTopInPromoteFlows();
                }}
              >
                <Icon className="ml-8">chevron_left</Icon>
                {t('COMMON.BACK')}
              </Button>
            )}
            {step < NUMBER_OF_STEPS && step !== NUMBER_OF_STEPS - 1 && (
              <Button
                disabled={!canStep && step !== 0}
                className="btn-white"
                onClick={() => {
                  setStep(step < NUMBER_OF_STEPS ? step + 1 : step);
                  scrollToTopInPromoteFlows();
                }}
              >
                {t('COMMON.CONTINUE')}
                <Icon className="mr-8">chevron_right</Icon>
              </Button>
            )}
            {step === NUMBER_OF_STEPS - 1 && (
              <Button
                className="btn-white"
                onClick={() => {
                  setPermissionDialogOpen(true);
                }}
                disabled={isCreatingWebsite}
              >
                {isCreatingWebsite ? (
                  <CircularProgress size={16} />
                ) : isEditMode ? (
                  t('WEBSITE-BUILDER.UPDATE-MY-WEBSITE')
                ) : (
                  t('WEBSITE-BUILDER.CREATE-MY-WEBSITE')
                )}
              </Button>
            )}
            {step === NUMBER_OF_STEPS && (
              <Button
                className="btn-white"
                onClick={() => {
                  handleDialogOutput(true);
                }}
              >
                {t('COMMON.DONE')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteGeneratorModal;
