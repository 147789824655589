import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import Loading from '@/components/utility/Loading';
import { WebsiteBuilderBackgrounds } from '@/constants/WebsiteBuilderBackgrounds';
import useArtist from '@/hooks/artist/useArtist';
import {
  WebsiteBuilderBackgroundColorModel,
  WebsiteBuilderBackgroundImageModel,
  WebsiteBuilderModel,
} from '@/models/WebsiteBuilder';

type ExtendedCSSProperties = React.CSSProperties & {
  '--card-background-color'?: string;
  '--text-color'?: string;
};

const WebsitePreviewPage = () => {
  const { t } = useTranslation();

  const { artist } = useArtist();

  const [chosenBackground, setChosenBackground] = useState<
    WebsiteBuilderBackgroundImageModel | WebsiteBuilderBackgroundColorModel
  >();
  const [style, setStyle] = useState<ExtendedCSSProperties>();

  const { watch } = useFormContext<WebsiteBuilderModel>();
  const formThemeBackground = watch('theme.background');
  const formThemeBackgroundName = watch('theme.background.name');

  const formArtistName = watch('artistName');
  const formPrimaryLinks = watch('primaryLinks');
  const formBio = watch('bio');

  const isImageModel = (
    background: WebsiteBuilderBackgroundImageModel | WebsiteBuilderBackgroundColorModel
  ): background is WebsiteBuilderBackgroundImageModel => 'url' in background;

  useEffect(() => {
    if (chosenBackground) {
      const isLight = chosenBackground.name.includes('light');
      const style = {
        backgroundImage: isImageModel(chosenBackground) ? `url(${chosenBackground.url})` : undefined,
        backgroundColor: !isImageModel(chosenBackground) ? chosenBackground.hex : undefined,
        '--card-background-color': isLight ? '#fafafa72' : '#26262666',
        '--text-color': isLight ? '#383838' : '#fafafa',
      };
      setStyle(style);
    }
  }, [chosenBackground]);

  useEffect(() => {
    if (formThemeBackground || formThemeBackgroundName) {
      const { images, colors } = WebsiteBuilderBackgrounds;
      const allBackgrounds = [...images, ...colors];
      const matchedBackground = allBackgrounds.find(
        (item) => item.name === formThemeBackground || item.name === formThemeBackgroundName
      );
      if (matchedBackground) {
        setChosenBackground(matchedBackground);
      }
    }
  }, [formThemeBackground, formThemeBackgroundName]);

  const logos = ['apple-music', 'deezer', 'facebook', 'instagram', 'spotify', 'tiktok', 'twitter', 'youtube'];

  if (!artist) {
    return <Loading size="small" />;
  }
  return (
    <>
      <h3>{t('WEBSITE-BUILDER.PREVIEW')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.PREVIEW-DESCRIPTION')}</p>
      <div className="website-preview mb20 m-auto mb300" style={style}>
        <div className="title-card p12">
          <h2>{formArtistName}</h2>
          <p>Powered by un:hurd music</p>
        </div>
        <div className="carousel-card">
          <img className="profile-pic" src={artist.details?.profileImageUrl} alt="" />
          <p className="mt10 small">{formBio}</p>
          <div className="d-flex flex-wrap jc-center mt10">
            {formPrimaryLinks
              ?.filter((link) => link.customText !== undefined)
              .map((item) => <Button key={item.link}>{item.customText}</Button>)}
          </div>
        </div>
        <div className="socials-card">
          {logos.map((item) => (
            <div key={item} className="socials-container">
              <img
                className={`${
                  (item === 'twitter' || item === 'apple-music') && chosenBackground?.name.includes('light')
                    ? 'invert'
                    : ''
                }`}
                src={`/images/logos/${item}-logo.svg`}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WebsitePreviewPage;
