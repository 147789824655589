import React from 'react';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

import { Icon } from '@mui/material';

import Loading from '@/components/utility/Loading';
import useWalkthrough from '@/hooks/website-builder/useWalkthrough';

const WebsiteWalkthrough = () => {
  const { walkthrough, walkthroughIsLoading } = useWalkthrough({ walkthrough: 'desktop' });

  return (
    <div className="max-w750 ml-auto mr-auto">
      {walkthroughIsLoading && (
        <div className="mt-auto mb-auto h400 d-flex jc-center" style={{ alignItems: 'center' }}>
          <Loading />
        </div>
      )}
      {walkthrough && <Lottie animationData={walkthrough} play loop />}
      <div className="card d-flex">
        <Icon className="mt-auto mb-auto">public</Icon>
        <p className="text-left pl8">
          We've combined the power of AI with our music and design expertise so you can put together a website in
          minutes.
        </p>
      </div>
    </div>
  );
};

export default WebsiteWalkthrough;
